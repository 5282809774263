import type {Directive} from 'vue'
import {vTooltip} from 'floating-vue'

export const tooltipDirectives: Directive = {
  beforeMount(el, binding) {
    binding = {...binding, value: {content: binding.value, theme: 'Rateboard'}}
    vTooltip.beforeMount(el, binding)
  },

  updated(el, binding) {
    binding = {...binding, value: {content: binding.value, theme: 'Rateboard'}}
    vTooltip.updated(el, binding)
  },

  beforeUnmount(el) {
    vTooltip.beforeUnmount(el)
  },
}
